import { lazy } from 'react';
//import { Navigate } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import PublicRoute from './PublicRoutes';
//import ProtectedRoute from './ProtectedRoute'; // Adjust the path as needed

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
//const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const ForgetPassword = Loadable(lazy(() => import('pages/authentication/ForgetPassword')));
const NotFound = Loadable(lazy(() => import('components/NotFound')));

// ==============================|| AUTH ROUTING ||============================== //
const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: 'login',
      element: (
        <PublicRoute>
          {' '}
          <AuthLogin />{' '}
        </PublicRoute>
      )
    },
    // {
    //   path: 'register',
    //   element: (
    //     <PublicRoute>
    //       <AuthRegister />
    //     </PublicRoute>
    //   )
    // },
    {
      path: 'reset-password',
      element: (
        <PublicRoute>
          <ForgetPassword />
        </PublicRoute>
      )
    },
    {
      path: '*',
      element: <NotFound />
    }
  ]
};

export default LoginRoutes;
