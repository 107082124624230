import React, { useState, useEffect } from 'react';
// axios
import axiosInstance from 'utils/axiosInstance';
// material-ui
import { Box, useMediaQuery } from '@mui/material';

import Profile from './Profile/index';
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const logOut = async () => {
    try {
      await axiosInstance.get(`/admin/log-out`);
      localStorage.clear();
      window.location.href = '/login';
    } catch (error) {
      console.log(error);
    }
  };

  // profile

  //const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const getProfile = async () => {
    //  setLoading(true);

    try {
      const data = await axiosInstance.get(`/admin/profile`);
      //console.log(data);
      setProfile(data.data);
      // setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);
  // console.log(loading, profile);

  return (
    <>
      <Box
        sx={{ width: '100%' }}
        style={{
          backgroundColor: 'red'
        }}
      />
      {matchesXs && <Box sx={{ width: '100%' }} />}

      <Profile logOut={logOut} profile={profile} />
    </>
  );
};

export default HeaderContent;
