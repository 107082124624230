// assets
import { DashboardOutlined, ChromeOutlined, UserOutlined, IdcardOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  ChromeOutlined,
  UserOutlined,
  IdcardOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: '',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'all-sessions',
      title: 'All Sessions',
      type: 'item',
      url: '/all-sessions',
      icon: icons.IdcardOutlined,
      breadcrumbs: false
    },
    {
      id: 'all-moderators',
      title: 'All Moderators',
      type: 'item',
      url: '/all-moderators',
      icon: icons.UserOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
