import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ProtectedRoute from './ProtectedRoute';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
//const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - all sessions
const AllSessions = Loadable(lazy(() => import('pages/extra-pages/AllSessions')));
// all moderators
const AllModerators = Loadable(lazy(() => import('pages/extra-pages/AllModerators')));
// one moderator
const OneModerator = Loadable(lazy(() => import('pages/extra-pages/OneModerator')));
// profile 

const Profile = Loadable(lazy(() => import('pages/extra-pages/Profile')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <DashboardDefault />{' '}
        </ProtectedRoute>
      )
    },

    {
      path: 'profile',
      element: (
        <ProtectedRoute>
          <Profile />
        </ProtectedRoute>
      )
    },

    {
      path: 'all-sessions',
      element: (
        <ProtectedRoute>
          <AllSessions />
        </ProtectedRoute>
      )
    },
    {
      path: 'all-moderators',
      element: (
        <ProtectedRoute>
          {' '}
          <AllModerators />
        </ProtectedRoute>
      )
    },
    {
      path: 'moderator/:id',
      element: (
        <ProtectedRoute>
          {' '}
          <OneModerator />
        </ProtectedRoute>
      )
    }
  ]
};

export default MainRoutes;
