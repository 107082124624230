import axios from 'axios';
export const url = (env) => {
  if (env === 'development') {
    return process.env.REACT_APP_DEV_URL;
  }
  if (env === 'production') {
    return process.env.REACT_APP_BACKEND_URL;
  }
};

const axiosInstance = axios.create({
  baseURL: url(process.env.NODE_ENV), // Replace with your backend URL
  withCredentials: true // Allow cookies to be sent with requests
});

export default axiosInstance;
