// ==============================|| LOGO  ||============================== //
const logo = process.env.REACT_APP_LOGO;
const Logo = () => {
  return (
    <>
      <img src={logo} alt="Logo" width="100" />
    </>
  );
};

export default Logo;
